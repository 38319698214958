import { Content, asLink } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import classNames from 'classnames';
import { Button } from 'ui/components/Button';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { NavBarBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import GetStartedModal from 'ui/layout/GetStarted/GetStartedModal';
import { PrismicNextImage } from '@prismicio/next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

/**
 * Props for `BottomCta`.
 */
export type BottomCtaProps = SliceComponentProps<Content.BottomCtaSlice>;

/**
 * Component for "BottomCta" Slices.
 */
const BottomCta = ({ slice }: BottomCtaProps): JSX.Element => {
  const [width] = useWindowsSize();
  const [openGetStarted, setOpenGetStarted] = useState(false);
  const router = useRouter();
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    };
  }, []);
  const btnProps = {
    ...(slice.primary.cta_action === 'Get Started Overlay'
      ? {
          onClick: () => {
            if (width > NavBarBreakpoint) {
              setOpenGetStarted(true);
            } else {
              // redirect
              router.push('/get-started');
            }
          },
        }
      : { href: asLink(slice.primary.cta_url), target: slice.primary.cta_target }),
  };
  return (
    <section
      className={classNames(styles.root, styles.dark, 'wide-container', 'color-changing-section')}
      style={{
        backgroundImage:
          width < ResponsiveBreakpoint ? 'none' : `url("${slice.primary.full_bleed_background_image.url}")`,
      }}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      data-background={'dark'}
    >
      <div className={styles.content}>
        <h2 className={styles.header} data-testid="heading">
          {slice.primary.header}
        </h2>
        <PrismicRichText
          className={styles.description}
          field={slice.primary.description}
          applyStyles={true}
          boldHighlight="primary"
        />
        {width < ResponsiveBreakpoint && (
          <div className={styles.imgWrapper}>
            <PrismicNextImage field={slice.primary.inline_image} />
          </div>
        )}
        <Button className={styles.btn} text={slice.primary.cta_text} {...btnProps} dataTestid="cta" />
        <GetStartedModal open={openGetStarted} onClose={() => setOpenGetStarted(false)} />
      </div>
    </section>
  );
};

export default BottomCta;
